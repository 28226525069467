import { PayloadAction, createSlice } from "@reduxjs/toolkit"

 interface CartTicketInterface {
   count: number
   price: number
 }

 interface EventCartInterface {
   [ticket_id: number]: CartTicketInterface
   slugTotalCount: number
   slugTotalPrice: number
   checkOutUrl?: string
 }

 export interface CartInterface {
   [slug: string]: EventCartInterface
 }

 const initialState: CartInterface = {}

 const cartSlice = createSlice({
   name: "cart",
   initialState,
   reducers: {
     incrementTicketCount: (
       state,
       action: PayloadAction<{
         slug: string
         ticket_id: number
         price: number
         count: number
       }>,
     ) => {
       const { slug, ticket_id, price, count } = action.payload

       if (!state[slug]) {
         // Clear cart when a new event is added (only one event in the cart at a time)
         Object.keys(state).forEach((key) => delete state[key])

         // Add the new event and ticket to the cart
         state[slug] = {
           [ticket_id]: { count, price },
           slugTotalCount: count,
           slugTotalPrice: price,
         }
       } else {
         const eventInCart = state[slug]
         const ticketInCart = eventInCart[ticket_id]

         // Calculate new counts and prices
         const newCount = ticketInCart ? ticketInCart.count + count : count
         const newTotalTickets = eventInCart.slugTotalCount + count
         const newTotalPrice = eventInCart.slugTotalPrice + price * count

         // Update the state with the new values
         state[slug] = {
           ...eventInCart,
           [ticket_id]: { count: newCount, price },
           slugTotalCount: newTotalTickets,
           slugTotalPrice: newTotalPrice,
         }
       }
     },

     decrementTicketCount: (
       state,
       action: PayloadAction<{
         slug: string
         ticket_id: number
         count: number
       }>,
     ) => {
       const { slug, ticket_id, count } = action.payload

       const eventInCart = state[slug]
       const ticketInCart = eventInCart[ticket_id]

       if (!ticketInCart) return

       if (ticketInCart.count === 1) {
         delete state[slug][ticket_id]
       } else {
         ticketInCart.count -= count
       }

       eventInCart.slugTotalCount -= count
       eventInCart.slugTotalPrice -= ticketInCart.price * count

       // Remove the event if no tickets are left
       if (eventInCart.slugTotalCount === 0) delete state[slug]
     },

     removeTicketFromCart: (
       state,
       action: PayloadAction<{ slug: string; ticket_id: number }>,
     ) => {
       const { slug, ticket_id } = action.payload

       const eventInCart = state[slug]
       const ticketInCart = eventInCart[ticket_id]

       if (!ticketInCart) return

       const { slugTotalCount, slugTotalPrice, checkOutUrl, ...rest } =
         eventInCart

       const updatedSlugTotalCount = slugTotalCount - ticketInCart.count
       const updatedSlugTotalPrice =
         slugTotalPrice - ticketInCart.count * ticketInCart.price

       // Remove the ticket and update the event totals
       delete eventInCart[ticket_id]
       state[slug] = {
         ...rest,
         slugTotalCount: updatedSlugTotalCount,
         slugTotalPrice: updatedSlugTotalPrice,
         ...(checkOutUrl && { checkOutUrl }),
       }

       // Remove the event if no tickets are left
       if (updatedSlugTotalCount === 0) delete state[slug]
     },

     removeSlugCart: (state, action: PayloadAction<string>) => {
       const slug = action.payload
       delete state[slug]
     },

     addCheckoutUrl: (
       state,
       action: PayloadAction<{ slug: string; checkOutUrl: string }>,
     ) => {
       const { slug, checkOutUrl } = action.payload
       state[slug] = {
         ...state[slug],
         checkOutUrl,
       }
     },

     clearCart: (state) => {
      Object.keys(state).forEach((slug) => delete state[slug])
    },
   },
 })

export const {
  incrementTicketCount,
  decrementTicketCount,
  removeTicketFromCart,
  removeSlugCart,
  addCheckoutUrl,
  clearCart,
} = cartSlice.actions

export const cartReducer = cartSlice.reducer
