import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"
import removeFalsyValues from "utils/remove-falsy.util"

export function useGetDashboardData(params: Record<string, any> = {}) {
  const cleanParams = removeFalsyValues(params)

  const { isLoading, data, error } = useQuery({
    queryKey: ["dashboardData", cleanParams],
    queryFn: async () => await handleGetRequest("admin/dashboard", cleanParams),
  })

  return {
    isLoading,
    data,
    error,
  }
}

export function useGetDashboardEvents({ period }: { period?: string | null }) {
  const cleanParams = removeFalsyValues({ is_active: 1, period })

  return useInfiniteQuery({
    queryKey: ["dashboardData", cleanParams],
    queryFn: async ({ pageParam }) =>
      await handleGetRequest("admin/events", {
        ...cleanParams,
        page: pageParam,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage?.data.meta.current_page
      const lastPage_ = lastPage?.data.meta.last_page

      const hasNextPage = currentPage < lastPage_

      return hasNextPage ? currentPage + 1 : undefined
    },
  })
}
