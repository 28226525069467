import { convertToFiles } from "pages/host-event/utils"
import { useState } from "react"
import { useDropzone } from "react-dropzone"
import {
  Upload,
  X,
  Image as ImageIcon,
  ChevronLeft,
  ChevronRight,
} from "react-feather"
import { RtFileUploaderProps } from "types/component-props.types"
import resizeImage from "utils/file-resizer"
import { showToaster } from "utils/show-toaster.util"
import { AnimatePresence, motion } from "framer-motion"

function RtFileUploader({
  handleFiles,
  setImageUrls,
  isMultiple = true,
  shouldResize = false,
  defaultFileUrls = [],
  maxFile = 0,
}: RtFileUploaderProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [isCarouselOpen, setIsCarouselOpen] = useState(false)

  const openCarousel = (index: number) => {
    setCurrentImageIndex(index)
    setIsCarouselOpen(true)
  }

  const closeCarousel = () => {
    setIsCarouselOpen(false)
  }

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % files.length)
  }

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + files.length) % files.length,
    )
  }
  const [files, setFiles] = useState<string[]>(defaultFileUrls)
  const onDrop = (acceptedFiles: File[]) => {
    if (maxFile === 1 && acceptedFiles.length > 1) {
      showToaster("error", `You can only upload ${maxFile} file.`)
      return
    }

    if (maxFile !== 1 && files.length + acceptedFiles.length > maxFile) {
      showToaster("error", `You can only upload a maximum of ${maxFile} files.`)
      return
    }

    const processFiles = (fileList: File[], resize = false) => {
      const filePromises = fileList.map(
        (file) =>
          new Promise<string>((resolve) => {
            const callback = (resizedFile: Blob) => {
              const url = URL.createObjectURL(resizedFile)
              resolve(url)
            }
            resize
              ? resizeImage({ file, maxWidth: 300, maxHeight: 300, callback })
              : callback(file)
          }),
      )

      Promise.all(filePromises).then((urls) => {
        let updatedFiles = urls

        if (maxFile === 1) {
          updatedFiles = urls.slice(0, 1)
        } else {
          updatedFiles = [...files, ...urls]
        }

        setFiles(updatedFiles)
        if (typeof setImageUrls === "function") setImageUrls(updatedFiles)
        convertToFiles(updatedFiles).then((fileObjects) =>
          handleFiles(fileObjects),
        )
      })
    }

    processFiles(acceptedFiles, shouldResize)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: isMultiple,
  })

  const removeFile = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation()
    const updatedFiles = files.filter((_, i) => i !== index)
    URL.revokeObjectURL(files[index])

    setFiles(updatedFiles)
    handleFiles(updatedFiles.map((file) => file as unknown as Blob))
  }
  return (
    <div className="relative">
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-rt-light-blue rounded-lg bg-rt-light-blue bg-opacity-5 p-8 w-full h-[28rem] flex flex-col justify-center items-center transition-all duration-300 ease-in-out hover:bg-opacity-10"
      >
        <input {...getInputProps()} />

        <AnimatePresence>
          {files.length > 0 ? (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
              className="w-full h-full mb-4"
            >
              {files.length === 1 ? (
                <div className="relative w-full h-full">
                  <img
                    src={files[0]}
                    alt="Uploaded file"
                    className="w-full h-full object-contain rounded-md"
                  />
                  <button
                    className="absolute top-2 right-2 p-1 bg-rt-violet text-white rounded-full opacity-70 hover:opacity-100 transition-opacity"
                    onClick={(event) => {
                      event.stopPropagation()
                      removeFile(0, event)
                    }}
                    aria-label="Remove file"
                  >
                    <X size={16} />
                  </button>
                </div>
              ) : (
                <div className="grid grid-cols-3 gap-4 w-full h-full overflow-y-auto p-2">
                  {files.map((file, index) => (
                    <motion.div
                      key={index}
                      className="relative aspect-square cursor-pointer"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.2, delay: index * 0.05 }}
                      onClick={() => openCarousel(index)}
                    >
                      <img
                        src={file}
                        alt={`Uploaded file ${index + 1}`}
                        className="w-full h-full object-cover rounded-md"
                      />
                      <button
                        className="absolute top-2 right-2 p-1 bg-rt-violet text-white rounded-full opacity-70 hover:opacity-100 transition-opacity"
                        onClick={(event) => {
                          event.stopPropagation()
                          removeFile(index, event)
                        }}
                        aria-label={`Remove file ${index + 1}`}
                      >
                        <X size={16} />
                      </button>
                    </motion.div>
                  ))}
                </div>
              )}
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="flex flex-col items-center"
            >
              <div className="w-20 h-20 bg-rt-light-blue bg-opacity-20 rounded-full flex items-center justify-center mb-4">
                <Upload className="text-rt-violet" size={32} strokeWidth={2} />
              </div>
              <p className="text-rt-gray text-lg mb-2">
                {isDragActive
                  ? "Drop the files here"
                  : "Drag and drop files here"}
              </p>
              <p className="text-rt-gray text-sm">or</p>
              <button className="mt-2 px-4 py-2 bg-rt-violet text-white rounded-md hover:bg-opacity-90 transition-colors">
                Browse Files
              </button>
            </motion.div>
          )}
        </AnimatePresence>

        {files.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            className="mt-4 flex items-center"
          >
            <ImageIcon size={18} className="text-rt-violet mr-2" />
            <span className="text-rt-gray">
              {files.length} {files.length > 0 ? "file" : "files"} selected
            </span>
          </motion.div>
        )}
      </div>

      <AnimatePresence>
        {isCarouselOpen && files.length > 1 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={closeCarousel}
          >
            <div
              className="relative max-w-4xl max-h-[80vh] w-full h-full"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={files[currentImageIndex]}
                alt={`Carousel image ${currentImageIndex + 1}`}
                className="w-full h-full object-contain"
              />
              <button
                className="absolute top-4 right-4 p-2 bg-rt-violet text-white rounded-full opacity-70 hover:opacity-100 transition-opacity"
                onClick={closeCarousel}
                aria-label="Close carousel"
              >
                <X size={24} />
              </button>
              <button
                className="absolute left-4 top-1/2 transform -translate-y-1/2 p-2 bg-rt-violet text-white rounded-full opacity-70 hover:opacity-100 transition-opacity"
                onClick={prevImage}
                aria-label="Previous image"
              >
                <ChevronLeft size={24} />
              </button>
              <button
                className="absolute right-4 top-1/2 transform -translate-y-1/2 p-2 bg-rt-violet text-white rounded-full opacity-70 hover:opacity-100 transition-opacity"
                onClick={nextImage}
                aria-label="Next image"
              >
                <ChevronRight size={24} />
              </button>
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-rt-violet bg-opacity-70 px-3 py-1 rounded-full text-white">
                {currentImageIndex + 1} / {files.length}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default RtFileUploader
