import SectionContainer from "components/layout/SectionContainer"
import DashboardStatCard from "./dashboard-stat-card"
import {
  useGetDashboardData,
  useGetDashboardEvents,
} from "hooks/api/useDashboard"
import Spinner from "components/loader/Spinner"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import ErrorFallback from "components/fallback/ErrorFallback"
import PeriodFilter from "./period-filter"
import removeFalsyValues from "utils/remove-falsy.util"
import AdminEventLink from "./admin-event-link"
import { EventInterFace } from "types/index.types"
import RtButton from "components/button/RtButton"
import useScrollIntoView from "hooks/useScrollIntoView"
import { useEffect, useRef } from "react"
import { PERIODS, TPeriodValue } from "constants/global.constants"
import getPeriodDisplay from "utils/getPeriodDisplay.util"
import NoItemsFallback from "components/fallback/NoItemsFallback"
import RtTitle from "components/title/RtTitle"

function AdminDashboard() {
  const { searchParams, handleSetUrlParams } = useRtSetUrlParams()
  const period = (searchParams.get("period") ||
    PERIODS.UPCOMING.value) as TPeriodValue

  const nextPageSpinnerRef = useRef(null)

  useScrollIntoView(nextPageSpinnerRef)

  const dashboardStatsParams = removeFalsyValues({
    period,
  })

  const { isLoading, error, data } = useGetDashboardData(dashboardStatsParams)

  const {
    isLoading: isEventsLoading,
    error: eventsError,
    data: eventsData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetDashboardEvents({ period })

  const noEvents = eventsData?.pages[0]?.data?.data.length === 0

  const title = getPeriodDisplay(period)
  useEffect(() => {
    if (!period) {
      handleSetUrlParams({ period: PERIODS.UPCOMING.value })
    }
  }, [period])

  if (eventsError || error) return <ErrorFallback />

  return (
    <>
      <SectionContainer>
        <div className="flex justify-end mb-4">
          <PeriodFilter />
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="grid grid-cols-3 gap-4">
            <>
              <DashboardStatCard
                stat={data.data.stats.events}
                statDisplayName="Events"
                to="/events"
              />
              <DashboardStatCard
                stat={data.data.stats.published}
                statDisplayName="Published"
                to="/events/?status=3"
              />
              <DashboardStatCard
                stat={data.data.stats.in_review}
                statDisplayName="In Review"
                to="/events/?status=2"
              />
              <DashboardStatCard
                stat={data.data.stats.tickets}
                statDisplayName="Tickets"
                to="/sales"
              />
              <DashboardStatCard
                overrideContainerClass="col-span-2"
                stat={`${data.data.stats.ticket_commission}/${data.data.stats.ticket_sales}`}
                statDisplayName="Commission/Sales"
                to="/sales"
              />
            </>
          </div>
        )}
      </SectionContainer>

      <SectionContainer>
        {isEventsLoading && <Spinner />}

        {noEvents ? (
          <NoItemsFallback title="There are no events for this period" />
        ) : (
          <>
            <div className="flex justify-between border-b border-b-rt-light-gray">
              <RtTitle overrideClass={`mb-3`}>{`${title} events`}</RtTitle>
              <i className="text-rt-gray">Days To Event</i>
            </div>

            {eventsData?.pages?.map((pages, idx) => (
              <div key={idx}>
                {pages?.data?.data.map((event: EventInterFace) => (
                  <AdminEventLink
                    period={period}
                    key={`admin-event-${event.slug}`}
                    event={event}
                  />
                ))}
              </div>
            ))}

            <SectionContainer overrideClass=" flex justify-center">
              {isFetchingNextPage && (
                <div ref={nextPageSpinnerRef} className="h-48 m-auto">
                  <Spinner />
                </div>
              )}

              {!isFetchingNextPage && hasNextPage && (
                <RtButton onClick={() => fetchNextPage()}>More events</RtButton>
              )}
            </SectionContainer>
          </>
        )}
      </SectionContainer>
    </>
  )
}

export default AdminDashboard
