import ScrollToTopButton from "components/button/ScrollToTop"
import TopNav from "components/nav/TopNav"
import { RAPID_TECH_INSIGHTS_DOMAIN } from "constants/global.constants"
import { Link, Outlet } from "react-router-dom"

function UserLayout() {
  return (
    <div className="bg-rt-light-violet min-h-screen flex flex-col">
      <TopNav />
      <main className="font-poppins flex-grow">
        <ScrollToTopButton />
        <Outlet />
      </main>
      <div className="flex justify-center items-center mt-auto mb-2">
        <p className="whitespace-nowrap">
          Made with <span className="text-rt-red">❤️</span> by
          <Link
            to={RAPID_TECH_INSIGHTS_DOMAIN}
            target="_blank"
            style={{
              color: "#0158b8",
              marginLeft: "4px",
            }}
          >
            Rapid Tech Insights
          </Link>
        </p>
      </div>
    </div>
  )
}

export default UserLayout
