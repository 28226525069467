import RtButton from "components/button/RtButton";
import RtFileUploader from "components/file-uploader/RtFileUploader";
import RTError from "components/input/RTError";
import RTFormHelperText from "components/input/RTFormHelperText";
import RtInput from "components/input/RtInput";
import RtLabel from "components/input/RtLabel";
import ContentContainer from "components/layout/ContentContainer";
import ActionLoadingModal from "components/modal/ActionLoadingModal";
import PageNav from "components/nav/PageNav";
import GenderSearchSelect from "components/search-select/GenderSearchSelect";
import LocationSearchSelect from "components/search-select/LocationSearchSelect";
import RtTitle from "components/title/RtTitle";
import { KcyFormDataType } from "types/form-data.types";
import { useNavigate } from "react-router-dom";


interface AddKycDetailsProps {
  formData: KcyFormDataType;
  handleChange: (value: { [key: string]: any }) => void;
  isAddingKyc: boolean;
  handleCreateKycDetails: () => void;
  isEdit?: boolean;
}

function AddKycDetails({
  formData,
  handleChange,
  isAddingKyc,
  handleCreateKycDetails,
  isEdit,
}: AddKycDetailsProps) {
  const navigate = useNavigate();

  const idPhotoURL =
    typeof formData?.id_photo === "string"
      ? formData?.id_photo
      : formData?.id_photo
      ? URL.createObjectURL(formData?.id_photo)
      : "";

  const passportPhotoURL =
    typeof formData?.passport === "string"
      ? formData?.passport
      : formData?.passport
      ? URL.createObjectURL(formData?.passport)
      : "";


  const onAddHostDetails = () => {
    handleCreateKycDetails();
    if (!isAddingKyc) {
      localStorage.setItem("isKycComplete", "true"); // Save KYC completion status
      navigate("/"); // Redirect to the home page or relevant page if needed
    }
  };


  return (
    <div>
      <PageNav overrideWrapper="p-0" backText="Home">
        <RtTitle overrideClass="mb-3">
          {isEdit ? "Edit" : "Add"} KYC details to proceed
        </RtTitle>
      </PageNav>

      <div className="mt-2">
        <p className="text-rt-gray">
          One-time KYC details are mandatory for hosting events.
        </p>
      </div>
      <ContentContainer overrideClass="grid grid-cols-1 gap-4">
        <RtInput
          inputName="firstname"
          inputType="text"
          inputPlaceholder="John"
          inputOverrideClass="bg-rt-white bg-opacity-100"
          label="First Name"
          inputValue={formData?.firstname}
          onInputChange={handleChange}
          isRequired
        />

        <RtInput
          inputName="lastname"
          inputType="text"
          inputPlaceholder="Doe"
          inputOverrideClass="bg-rt-white bg-opacity-100"
          label="Last Name"
          inputValue={formData?.lastname || ""}
          onInputChange={handleChange}
          isRequired
        />

        <RtInput
          inputName="phone_number"
          inputType="number"
          inputPlaceholder="E.g 0722334455"
          inputOverrideClass="bg-rt-white bg-opacity-100"
          label="Phone Number"
          inputValue={formData?.phone_number || ""}
          onInputChange={handleChange}
          isRequired
        />

        <RtInput
          inputName="id_number"
          inputType="text"
          inputPlaceholder="12345678"
          inputOverrideClass="bg-rt-white bg-opacity-100"
          label="ID number"
          inputValue={formData?.id_number || ""}
          onInputChange={handleChange}
          isRequired
        />

        <RtInput
          inputName="company_name"
          inputType="text"
          inputPlaceholder="Company Inc."
          inputOverrideClass="bg-rt-white bg-opacity-100"
          label="Company Name"
          inputValue={formData?.company_name || ""}
          onInputChange={handleChange}
        />

        <div>
          <RtLabel label="Location" isRequired />

          <LocationSearchSelect
            selectedLocation={formData?.location_place_id}
            handleSelected={(selectedOption) =>
              handleChange({ location_place_id: selectedOption || undefined })
            }
          />
          <RTError inputName="location_place_id" />
        </div>

        <div>
          <RtLabel label="Gender" isRequired />
          <GenderSearchSelect
            selectedGender={formData.gender}
            handleSelectedGenderChange={(selectedGender) =>
              handleChange({ gender: selectedGender })
            }
          />

          <RTError inputName="gender" />
        </div>

        <RtInput
          inputName="nok_name"
          inputType="text"
          inputPlaceholder="John"
          inputOverrideClass="bg-rt-white bg-opacity-100"
          label="Next of kin name"
          inputValue={formData?.nok_name || ""}
          onInputChange={handleChange}
          isRequired
        />

        <RtInput
          inputName="nok_phone_number"
          inputType="number"
          inputPlaceholder="E.g 0722334455"
          inputOverrideClass="bg-rt-white bg-opacity-100"
          label="Next of kin phone number"
          inputValue={formData?.nok_phone_number || ""}
          onInputChange={handleChange}
          isRequired
        />

        <div>
          <RtLabel label="Identification card photo" isRequired />

          <RTFormHelperText
            overrideClass="text-rt-gray"
            helperText="Upload the image of your national identity card"
          />

          <RtFileUploader
            defaultFileUrls={[idPhotoURL]}
            handleFiles={(files) => handleChange({ id_photo: files[0] })}
            shouldResize
            maxFile={1}
          />

          <RTError inputName="id_photo" />
        </div>

        <div>
          <RtLabel label="Selfie" isRequired />

          <RTFormHelperText
            overrideClass="text-rt-gray"
            helperText="Upload the image of your self"
          />

          <RtFileUploader
            defaultFileUrls={[passportPhotoURL]}
            handleFiles={(files) => handleChange({ passport: files[0] })}
            shouldResize
            maxFile={1}
          />

          <RTError inputName="passport" />
        </div>
      </ContentContainer>

      <div className="mt-5">
        <RtButton
          overrideClass="w-full"
          onClick={onAddHostDetails}
          disabled={isAddingKyc}
        >
          {isEdit ? "UPDATE HOST DETAILS" : "ADD HOST DETAILS"}
        </RtButton>

        <ActionLoadingModal
          isOpen={isAddingKyc}
          loadingText={`Your KYC details are being ${
            isEdit ? "updated" : "added"
          }`}
        />
      </div>
    </div>
  );
}

export default AddKycDetails
