import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ModalWrapperProps } from "types/component-props.types";
import BaseModal from "./BaseModal";
import { Edit, LogOut, Save } from "react-feather";
import RtAvatar from "components/avatar";
import { useLogout } from "hooks/api/useLogout";
import { useEditProfile } from "hooks/api/useEditProfile";
import RtInput from "components/input/RtInput";

function UserProfileModal({ isOpen, toggleModal }: ModalWrapperProps) {
  const user = useSelector((state: any) => state.user);
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    name: user.name,
    email: user.email,
    phone_number: user.phone_number,
  });
  const [initialData, setInitialData] = useState(profileData);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone_number: "",
  });

  const queryClient = useLogout(toggleModal);

  const editProfile = useEditProfile({
    onSuccess: () => {
      setIsEditing(false);
      setUnsavedChanges(false); // Reset unsaved changes after saving
      if (toggleModal) toggleModal();
    },
  });

  useEffect(() => {
    if (isOpen) {
      setInitialData({
        name: user.name,
        email: user.email,
        phone_number: user.phone_number,
      });
      setProfileData({
        name: user.name,
        email: user.email,
        phone_number: user.phone_number,
      });
      setUnsavedChanges(false);
    }
  }, [isOpen, user]);

  const validateFields = () => {
    let isValid = true;
    const newErrors = { name: "", email: "", phone_number: "" };

    if (!/^[a-zA-Z\s]+$/.test(profileData.name)) {
      newErrors.name = "Name should contain only letters and spaces";
      isValid = false;
    }
    if (!/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/.test(profileData.email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }
    if (!/^\+?\d{10}$/.test(profileData.phone_number)) {
      newErrors.phone_number = "Phone number must be 10 digits";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (updatedField: { [key: string]: string }) => {
    const updatedProfileData = { ...profileData, ...updatedField };
    setProfileData(updatedProfileData);
    setUnsavedChanges(JSON.stringify(updatedProfileData) !== JSON.stringify(initialData));
  };

  const handleSave = () => {
    if (validateFields()) {
      const dataToUpdate = { ...profileData };
      editProfile.mutate(dataToUpdate);
      setUnsavedChanges(false);
    }
  };

  const handleLogout = () => {
    queryClient.mutate();
  };

  const handleClose = () => {
    if (unsavedChanges) {
      const discard = window.confirm("You have unsaved changes. Do you want to discard them?");
      if (!discard) return;
    }
    if (toggleModal) toggleModal();
  };

  return (
    <BaseModal isOpen={isOpen} onClose={handleClose}>
      <div className="flex flex-col items-center mb-2">
        <RtAvatar name={profileData.name} />
        <div className="mt-4 flex flex-col gap-1 text-center">
          {isEditing ? (
            <>
              <RtInput
                label="Name"
                inputName="name"
                inputValue={profileData.name}
                onInputChange={handleChange}
                errorMessage={errors.name}
              />
              <RtInput
                label="Email"
                inputName="email"
                inputValue={profileData.email}
                onInputChange={handleChange}
                inputType="email"
                errorMessage={errors.email}
              />
              <RtInput
                label="Phone Number"
                inputName="phone_number"
                inputValue={profileData.phone_number}
                onInputChange={handleChange}
                inputType="tel"
                errorMessage={errors.phone_number}
              />
            </>
          ) : (
            <>
              <p>{profileData.name}</p>
              <span>{profileData.email}</span>
              <span>{profileData.phone_number}</span>
            </>
          )}
        </div>
      </div>

      <div className="mt-4 flex justify-between border-t pt-2">
        {isEditing ? (
          <button className="text-rt-green" onClick={handleSave}>
            <Save size={15} /> Save
          </button>
        ) : (
          <button className="text-rt-blue" onClick={() => setIsEditing(true)}>
            <Edit size={15} /> Edit Profile
          </button>
        )}
        <button className="text-rt-red" onClick={handleLogout}>
          <LogOut size={15} /> Log out
        </button>
      </div>
    </BaseModal>
  );
}

export default UserProfileModal;
