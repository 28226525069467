import {
  Calendar,
  Clock,
  Loader,
  MapPin,
  Zap,
  User,
  Link2,
} from "react-feather"
import RtButton from "components/button/RtButton"
import { EventCardProps } from "types/component-props.types"
import ContentContainer from "components/layout/ContentContainer"
import {
  formatDateToDisplay,
  getFormattedLocaleTime,
} from "utils/date-formatter.util"
import { isHost, isAdmin } from "utils/user.util"
import {
  EVENT_CANCELLED,
  EVENT_REJECTED,
  EVENT_DRAFT,
  EVENT_IN_REVIEW,
  EVENT_PUBLISHED,
} from "constants/global.constants"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { cn } from "utils/cn.util"
import { motion } from "framer-motion"
import ImageCarousel from "components/image-display/image-carousel"
import eventImage from "../../assets/images/event.jpg"

const eventStatusColorMap: { [key: string]: string } = {
  [EVENT_DRAFT]: "text-rt-gray",
  [EVENT_IN_REVIEW]: "text-yellow-500",
  [EVENT_PUBLISHED]: "text-rt-green",
  [EVENT_CANCELLED]: "text-rt-red",
  [EVENT_REJECTED]: "text-rt-red",
}

function EventCard({
  preview,
  showEventAbout,
  // isAdmin,
  event,
  showEventImage = true,
  handleClickCard,
  canBuyTicket = true,
  overrideClass,
  carouselEvents,
}: EventCardProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const navigateToEventDetails = () => {
    if (handleClickCard) {
      handleClickCard(event.slug)
      return
    }
    navigate(`/events/${event.slug}`)
  }

  // this page can only handle a valid event object
  if (!event) return null

  const statusColorBg = eventStatusColorMap[event.status!]

  const canViewEventStatus =
    (isHost() || isAdmin()) && location.pathname !== "/" && !preview

  return (
    <motion.div
      key={event.slug}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div
        className={cn(
          "bg-rt-white flex flex-col justify-center rounded-md h-full",
          !preview && "shadow-sm hover:shadow-md shadow-rt-dark",
          overrideClass,
        )}
      >
        {(!showEventImage || !preview) &&
          (event.image_urls && event.image_urls.length > 0 ? (
            <div className="relative w-full flex-grow">
              <ImageCarousel images={event.image_urls} eventName={event.name} />
            </div>
          ) : (
            <div className="relative w-full flex-grow">
              <img
                className="w-full h-52 object-contain object-center rounded-t-md"
                src={eventImage}
                alt={event.name || eventImage}
              />
            </div>
          ))}

        <ContentContainer
          overrideClass={cn(!preview && "border-none", "flex-1")}
        >
          <div className="flex flex-col items-stretch gap-2 lg:gap-4 text-sm lg:text-mg text-rt-gray">
            <div className="text-rt-blue text-2xl font-raleway font-semibold flex mb-2 ">
              <h1 role="button" onClick={navigateToEventDetails}>
                {event.name}
              </h1>
              <Link2 size={12} />
            </div>

            {showEventAbout ? (
              <div className="flex flex-row items-center">
                <User
                  className="text-rt-blue mr-1 pr-1"
                  size={20}
                  strokeWidth={2}
                />
                {/* TODO: need to link to some page where we can see the host and their profile and all their events */}
                <Link
                  to="#"
                  className="text-md text-rt-blue border-b border-rt-blue mr-1"
                >
                  {event.host_name}
                </Link>
                {/* TODO: need to link to some page where we can see all events for the given category */}
                <Link
                  to="#"
                  className="inline-flex rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 ml-4"
                >
                  {event.category_name}
                </Link>
              </div>
            ) : null}

            <div className="flex flex-row items-center">
              <Calendar
                className=" text-rt-blue mr-1 pr-1"
                size={20}
                strokeWidth={2}
              />
              <p className=" font-light">
                {formatDateToDisplay(event.start_date)} {"-"}
                {formatDateToDisplay(event.end_date)}
              </p>
            </div>

            <div className="flex flex-row items-center">
              <Clock
                className=" text-rt-blue mr-1 pr-1"
                size={20}
                strokeWidth={2}
              />
              <p className=" font-light">
                {getFormattedLocaleTime(event.start_date)} -{" "}
                {getFormattedLocaleTime(event.end_date)}
              </p>
            </div>

            {canViewEventStatus && (
              <div className="flex flex-row items-center">
                <Loader
                  className={cn("mr-1 pr-1 text-rt-gray", statusColorBg)}
                  size={20}
                  strokeWidth={2}
                />
                <p className={cn("text-md font-bold", statusColorBg)}>
                  {event.event_status ?? event.status_text}
                </p>
              </div>
            )}

            {/* TODO: should be a clickable link  */}
            <div className="flex flex-row items-center gap-2">
              <MapPin className="text-rt-blue" size={20} strokeWidth={2} />
              <p
                className={cn(
                  carouselEvents ? carouselEvents : "font-light text-sm",
                )}
                title={event.location_name}
              >
                {event.location_name}
              </p>
            </div>

            <div className="flex flex-row mt-2">
              <p className="text-rt-blue font-black">
                {event.price ? event.price : ""}
              </p>
            </div>

            {showEventAbout ? (
              <div className="text-rt-dark-blue  mt-2">
                <h6 className="font-semibold font-raleway text-lg text-rt-blue">
                  Description
                </h6>

                <p className="leading-6t text-lg whitespace-normal mt-1">
                  {event.description}
                </p>
              </div>
            ) : null}

            {!preview && canBuyTicket ? (
              <div>
                <RtButton
                  overrideClass="my-2 w-full"
                  icon={<Zap color="white" className="mr-2" strokeWidth={2} />}
                  onClick={navigateToEventDetails}
                >
                  {isAdmin() ? "View Event" : "Buy Ticket"}
                </RtButton>
              </div>
            ) : null}
          </div>
        </ContentContainer>
      </div>
    </motion.div>
  )
}

export default EventCard
